import { render, staticRenderFns } from "./App.vue?vue&type=template&id=419a116f&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=419a116f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419a116f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/RevolutionCloud_ra-web-admin_pF1p/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('419a116f')) {
      api.createRecord('419a116f', component.options)
    } else {
      api.reload('419a116f', component.options)
    }
    module.hot.accept("./App.vue?vue&type=template&id=419a116f&scoped=true&", function () {
      api.rerender('419a116f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account/center/page/App.vue"
export default component.exports