var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-list" },
    [
      _c("a-list", {
        attrs: {
          grid: { gutter: 24, lg: 3, md: 2, sm: 1, xs: 1 },
          dataSource: _vm.dataSource,
        },
        scopedSlots: _vm._u([
          {
            key: "renderItem",
            fn: function (item) {
              return _c(
                "a-list-item",
                {},
                [
                  _c(
                    "a-card",
                    { attrs: { hoverable: true } },
                    [
                      _c(
                        "a-card-meta",
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "margin-bottom": "3px" },
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c("a-avatar", {
                            staticClass: "card-avatar",
                            attrs: {
                              slot: "avatar",
                              src: item.avatar,
                              size: "small",
                            },
                            slot: "avatar",
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "meta-cardInfo",
                              attrs: { slot: "description" },
                              slot: "description",
                            },
                            [
                              _c("div", [
                                _c("p", [_vm._v("活跃用户")]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(_vm._s(item.activeUser)),
                                    _c("span", [_vm._v("万")]),
                                  ]),
                                ]),
                              ]),
                              _c("div", [
                                _c("p", [_vm._v("新增用户")]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm._f("NumberFormat")(item.newUser))
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { staticClass: "ant-card-actions", slot: "actions" },
                        [
                          _c(
                            "a",
                            [_c("a-icon", { attrs: { type: "download" } })],
                            1
                          ),
                          _c(
                            "a",
                            [_c("a-icon", { attrs: { type: "edit" } })],
                            1
                          ),
                          _c(
                            "a",
                            [_c("a-icon", { attrs: { type: "share-alt" } })],
                            1
                          ),
                          _c(
                            "a",
                            [
                              _c(
                                "a-dropdown",
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "ant-dropdown-link",
                                      attrs: { href: "javascript:;" },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "ellipsis" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu",
                                    {
                                      attrs: { slot: "overlay" },
                                      slot: "overlay",
                                    },
                                    [
                                      _c("a-menu-item", [
                                        _c(
                                          "a",
                                          { attrs: { href: "javascript:;" } },
                                          [_vm._v("1st menu item")]
                                        ),
                                      ]),
                                      _c("a-menu-item", [
                                        _c(
                                          "a",
                                          { attrs: { href: "javascript:;" } },
                                          [_vm._v("2nd menu item")]
                                        ),
                                      ]),
                                      _c("a-menu-item", [
                                        _c(
                                          "a",
                                          { attrs: { href: "javascript:;" } },
                                          [_vm._v("3rd menu item")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }